import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { blue, gray } from 'design-system/colors';
import { Form, Checkbox } from 'antd';
import Input from 'components/common/AntInput';
import Button from 'components/common/Button';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { login } from 'helpers/api';
import { openNotification } from 'helpers/notification';
import { AppContext } from 'store/AppContext';

const LoginForm = withRouter(({ history, className }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const { dispatch } = useContext(AppContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const account = await login({
        username,
        password,
      });
      await dispatch({
        type: 'LOGIN',
        payload: account,
      });
      history.push('/');
    } catch (error) {
      setLoading(false);
      openNotification({
        type: 'error',
        title: error,
      });
    }
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit}>
        <div className="form-header">
          <h1 className="form-title">Industrial Emmission Monitoring System</h1>
          <h2 className="form-subtitle">[I.E.M.S.]</h2>
        </div>
        <div className="form-body">
          <Input
            size="normal"
            label="username"
            value={username}
            placeholder="Enter username"
            onChange={({ target: { value } }) => setUsername(value)}
          />
          <Input
            size="normal"
            label="password"
            value={password}
            type="password"
            placeholder="Enter password"
            onChange={({ target: { value } }) => setPassword(value)}
            marginBottom="25"
          />
          <div className="form-settings">
            <Checkbox>Keep me signed In</Checkbox>
            <Link to="/forgot_password">Forgot Password?</Link>
          </div>

          <Button
            appearance="blue"
            size="large"
            htmlType="submit"
            disabled={loading}
            loading={loading}
          >
            {loading ? 'Logging in...' : 'Sign in'}
          </Button>
        </div>
      </form>
    </div>
  );
});

export default styled(LoginForm)`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: var(--font-family);

  form {
    width: 100%;
    .form-body {
      label {
        font-size: 14px;
        letter-spacing: -0.2px;
      }
      input {
        height: 50px;
        border: solid 1px #c3cad9;
        font-size: 14px;
        border-radius: 8px;
        &:focus {
          border: solid 1px #1565d8;
        }
      }
    }
  }
  .form-header {
    margin-bottom: 39px;

    .form-title {
      margin-top: 5rem;
      text-align: center;
      /* font-size: 36px; */
      font-weight: 300;
      line-height: 0.94;
      color: #183b56;
      margin-bottom: 18px;
      font-family: var(--font-family);
      margin-bottom: 40px;
      @media screen and (max-width: 769px) {
        line-height: 1.5;
        margin-top: unset;
      }
    }
    .form-subtitle {
      margin-top: 1rem;
      /* font-size: 36px; */
      font-weight: 250;
      line-height: 0.94;
      color: #183b56;
      font-family: var(--font-family);
      opacity: 0.6;
      text-align: center;
      margin-bottom: 40px;
      @media screen and (max-width: 769px) {
        margin-top: unset;
      }
    }

    .subtitle {
      font-size: 15px;
      line-height: 1.47;
      color: ${blue.faint};
    }
  }

  button {
    width: 100% !important;
    border-radius: 8px !important;
    margin-bottom: 24px;
  }
  .form-settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    .ant-checkbox + span {
      color: ${gray.misc2} !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background: ${blue.primary} !important;
    }
    a {
      color: #0c5bab !important;
      font-weight: 600;
    }
  }
  .form-footer {
    line-height: 1.47;
    color: ${gray.misc2};

    a {
      color: #0c5bab !important;
      font-weight: 500;
      margin-left: 15px;
    }

    .error {
      margin-bottom: 5px !important;
      color: red;
    }
  }
`;
