import axios from 'axios';
import download from 'downloadjs';
import { openNotification } from 'helpers/notification';
import { getErrorDesc } from './utils';

export const baseURL = window.BASE_URL;

export const getToken = () => {
  const serializedState = localStorage.getItem('IEMS');
  const auth = JSON.parse(serializedState);
  return auth.auth.token;
};

export const makeApiCall = (axiosConfigObj) => {
  const config = {
    ...axiosConfigObj,
    baseURL,
  };
  return new Promise((resolve, reject) => {
    return axios(config)
      .then(({ data }) => {
        if (data.result.status.code !== '00') return reject(data);
        return resolve(data);
      })
      .catch((err) => {
        const { response, request, message } = err;
        let error = response
          ? response.data.message
          : request
          ? 'Network error, please try again later'
          : message;
        if (response) {
          if (!response.data.message) {
            error = response;
          }
        }
        return reject(error);
      });
  });
};

export const downloadCertificate = async (ref) => {
  const url = `/Payment/DownloadCertificate/${ref}`;
  try {
    openNotification({
      type: 'info',
      title: 'Download started...',
      message: 'Your certificate is being downloaded in the background',
      duration: 10,
    });
    const response = await axios(url, { responseType: 'blob' });
    if (response.data.statusCode === 404) {
      throw new Error('No certificate found for the selected report');
    }
    return download(response.data, 'certificate.pdf', 'application/pdf');
  } catch (error) {
    return openNotification({
      type: 'error',
      title: 'File download failed',
      message: error.message,
    });
  }
};

export const getReports2 = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const reportList = await makeApiCall({
        url: `/Report`,
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(reportList);
    } catch (error) {
      return reject('Error fetching list of reports');
    }
  });
};

export const getFacilities = (params) => {
  let url = '/Facility?Start=0&Stop=100000';
  if (params) url = `/Facility${params}`;

  return new Promise(async (resolve, reject) => {
    try {
      const facilities = await makeApiCall({
        url: url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(facilities);
    } catch (error) {
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      else if (
        error &&
        error.result &&
        error.result.status &&
        error.result.status.desc === 'NOT FOUND'
      )
        return resolve(null);
      else if (!error) {
        return resolve();
      } else reject('Error fetching list of facilities');
    }
  });
};

export const getFacilityAdhocReport = (facilityId, reportId) => {
  let url = `/Facility/${facilityId}/SamplePoints?ReportID=${reportId}`;

  return new Promise(async (resolve, reject) => {
    try {
      const adhocReport = await makeApiCall({
        url: url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(adhocReport);
    } catch (error) {
      reject(error);
    }
  });
};

export const getReports = (params) => {
  let url = `/Report?limit=100000`;
  if (params) url = `/Report${params}`;
  return new Promise(async (resolve, reject) => {
    try {
      const reports = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(reports);
    } catch (error) {
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      return reject(error);
    }
  });
};
export const getIcons = () => {
  let url = `/Icon`;
  return new Promise(async (resolve, reject) => {
    try {
      const icons = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(icons);
    } catch (error) {
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      return reject(error);
    }
  });
};

export const getFacility = (facilityId, year = new Date().getFullYear()) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Facility/${facilityId}/FacilityReport?year=${year}`;
      const facility = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(facility);
    } catch (error) {
      return reject('Error fetching facility');
    }
  });
};

export const getFacilityReport = (facilityId, qtr, year = new Date().getFullYear()) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Facility/${facilityId}/QuarterReport/${qtr}?year=${year}`;
      let facilityReport = null;
      facilityReport = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(facilityReport);
    } catch (error) {
      return reject('Error fetching facility report');
    }
  });
};

export const getAdhocReport = (facilityId, year = new Date().getFullYear()) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Facility/${facilityId}/FacilityReport?adhoc=true&year=${year}`;
      let facilityReport = null;
      facilityReport = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(facilityReport);
    } catch (error) {
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      return reject('Error fetching facility report');
    }
  });
};

export const getComparativeReport = (facilityId, qtr, year = new Date().getFullYear()) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Facility/${facilityId}/ComparativeReport?year=${year}`;
      let comparativeReport = null;
      comparativeReport = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(comparativeReport);
    } catch (error) {
      return reject('Error fetching facility report');
    }
  });
};

export const getSamplePointRecord = (samplePointId, reportId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Report/${reportId}/SamplePoint/${samplePointId}`;
      let sptRecord = null;
      sptRecord = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(sptRecord);
    } catch (error) {
      return reject('Error fetching sample points record');
    }
  });
};

export const sendSamplePointRecord = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/SamplePointResult`;
      let sptRecord = null;
      sptRecord = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'post',
        data,
      });
      return resolve(sptRecord);
    } catch (error) {
      return reject(error);
    }
  });
};
export const uploadEIACert = (facilityId, dataURL) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Facility/${facilityId}/UploadEiaCert`;
      let uploads = null;
      uploads = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'post',
        data: { eiaCert: dataURL },
      });
      return resolve(uploads);
    } catch (error) {
      return reject(error);
    }
  });
};

export const lockReport = (reportId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Report/${reportId}/LockReport`;
      let lockReport = null;
      lockReport = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'patch',
        data: {},
      });
      return resolve(lockReport);
    } catch (error) {
      return reject(error);
    }
  });
};
export const publishFacilityReport = (reportId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Report/${reportId}/PublishReport`;
      let sptRecord = null;
      sptRecord = await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'patch',
        data: {},
      });
      return resolve(sptRecord);
    } catch (error) {
      return reject(error);
    }
  });
};

export const generateFacilityReport = (facilityId, year = new Date().getFullYear()) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/Facility/${facilityId}/FacilityReport?year=${year}`;
      await makeApiCall({
        url,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve();
    } catch (error) {
      return reject('Error generating facility report');
    }
  });
};

export const getProducts = (urlParams) => {
  return new Promise(async (resolve, reject) => {
    try {
      const products = await makeApiCall({
        url: urlParams ? urlParams : `/Product`,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(products);
    } catch (error) {
      return reject('Error fetching list of products');
    }
  });
};

export const getRawMaterials = (urlParams) => {
  return new Promise(async (resolve, reject) => {
    try {
      const rawMaterials = await makeApiCall({
        url: urlParams ? urlParams : `/RawMaterial`,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(rawMaterials);
    } catch (error) {
      return reject('Error fetching list of raw materials');
    }
  });
};

export const getWastes = (urlParams) => {
  return new Promise(async (resolve, reject) => {
    try {
      const wastes = await makeApiCall({
        url: urlParams ? urlParams : `/Waste`,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(wastes);
    } catch (error) {
      return reject('Error fetching list of wastes');
    }
  });
};

export const getSectors = (urlParams) => {
  return new Promise(async (resolve, reject) => {
    try {
      const sectors = await makeApiCall({
        url: urlParams ? urlParams : `/Sector`,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(sectors);
    } catch (error) {
      return reject(error);
    }
  });
};
export const getRegions = (urlParams) => {
  return new Promise(async (resolve, reject) => {
    try {
      const zones = await makeApiCall({
        url: urlParams ? urlParams : `/Zone`,
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(zones);
    } catch (error) {
      return reject('Error fetching list of regions');
    }
  });
};

export const getParameterGroups = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: parameterGroups } = await makeApiCall({
        url: '/Lookup/ParameterGroup',
        method: 'get',
      });
      console.log(parameterGroups, 'from api js');
      return resolve(parameterGroups);
    } catch (error) {
      return reject('Error fetching parameter groups');
    }
  });
};

export const getPayableAmount = ({ facilityId, quarter }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        payment: { amount, paymentType, paymentYear, paymentPeriod },
      } = await makeApiCall({
        url: `/Payment/GetPayableAmount/${facilityId}/${quarter}`,
        method: 'get',
      });
      return resolve({ amount, paymentType, paymentYear, paymentPeriod });
    } catch (error) {
      return reject('Could not determine amount to be paid');
    }
  });
};

export const checkReportStatus = ({ facilityId, quarter, accountId }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { currentStatus, samplepoint } = await makeApiCall({
        url: `ReportSchedule/ReportStatus/${facilityId}/${new Date().getFullYear()}/${quarter}/${accountId}`,
        method: 'get',
      });
      return resolve({ currentStatus, samplepoint });
    } catch (error) {
      return reject('Could not check report status');
    }
  });
};

export const initiatePayment = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { refNo } = await makeApiCall({
        url: '/Payment/MakePayment',
        method: 'post',
        data,
      });
      return resolve(refNo);
    } catch (error) {
      return reject('Could not generate payment reference');
    }
  });
};

export const createOrLoadReport = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        report: { reportId, reportTitle },
      } = await makeApiCall({
        url: '/ReportSchedule/Create',
        method: 'post',
        data,
      });
      return resolve({ reportId, reportTitle });
    } catch (data) {
      if (data && data.report) {
        const {
          report: { reportTitle, reportId },
        } = data;
        return resolve({ reportId, reportTitle });
      } else {
        return reject('Something went wrong, please contact the system admin');
      }
    }
  });
};

export const getSamplePoints = ({ facilityId, group, quarter }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await makeApiCall({
        url: `/Lookup/SamplePoint/${facilityId}/${group}/${quarter}`,
        method: 'get',
      });
      return resolve(data);
    } catch (error) {
      return reject('Something went wrong, please contact the system admin');
    }
  });
};

export const getResultData = ({ facilityId, reportId, group }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { resultsData } = await makeApiCall({
        url: `/Results/ResultData/${reportId}/${facilityId}/${group}`,
        method: 'get',
      });
      return resolve(resultsData);
    } catch (error) {
      return reject('Something went wrong, please contact the system admin');
    }
  });
};

export const getUnits = ({ group }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await makeApiCall({
        url: `/Lookup/ParameterUnit/${group}`,
        method: 'get',
      });
      return resolve(data);
    } catch (error) {
      return reject('Something went wrong, please contact the system admin');
    }
  });
};

export const saveResultData = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await makeApiCall({
        url: '/Results/Create',
        method: 'post',
        data,
      });
      return resolve('Sample point data saved successfully');
    } catch (error) {
      return reject('Could not save result data, please contact the system admin');
    }
  });
};

export const login = ({ username, password }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const account = await makeApiCall({
        url: '/Account/Login',
        method: 'post',
        data: {
          username,
          password,
        },
      });
      return resolve(account);
    } catch (error) {
      return reject('Login failed. Invalid username or password');
    }
  });
};

export const getStates = (urlParam) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const states = await makeApiCall({
        url: urlParam ? urlParam : '/State',
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });

      return resolve(states);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getLgas = (urlParam) => {
  return new Promise(async (resolve, reject) => {
    try {
      const lgas = await makeApiCall({
        url: urlParam ? urlParam : '/Lga',
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });

      return resolve(lgas);
    } catch (error) {
      return reject('Error fetching lists of local government areas');
    }
  });
};

export const getConsultants = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const consultants = await makeApiCall({
        url: '/Account?UserCat=3',
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });
      return resolve(consultants);
    } catch (error) {
      return reject('Error fetching lists of consultants');
    }
  });
};

export const getMeasurements = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const measurements = await makeApiCall({
        url: '/Measurement',
        headers: { Authorization: `bearer ${getToken()}` },
        method: 'get',
      });

      return resolve(measurements);
    } catch (error) {
      return reject('Error fetching lists of measurements');
    }
  });
};

export const getUserRoles = (accountId) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const roles = await makeApiCall({
        url: `/role?AccountId=${accountId}`,
        method: 'get',
        headers: { Authorization: `bearer ${token}` },
      });
      return resolve(roles);
    } catch (error) {
      return reject(error);
    }
  });
};

// SEND EMAIL INVITE TO USERS
export const inviteUsers = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const users = await makeApiCall({
        url: '/UserInvite',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });

      return resolve(users);
    } catch (error) {
      return reject(error);
    }
  });
};

export const getUserEmail = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userEmail = await makeApiCall({
        url: `/UserInvite/${id}/GetEmail`,
        method: 'get',
      });
      console.log(userEmail, '===');
      return resolve(userEmail);
    } catch (error) {
      console.log(error, '>>>');
      if (
        error &&
        error.result &&
        error.result.status &&
        error.result.status.desc === 'NOT FOUND'
      ) {
        return resolve(error);
      }
      return reject(error);
    }
  });
};
export const createAdhocReport = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const adhocReport = await makeApiCall({
        url: '/Report',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data,
      });

      return resolve(adhocReport);
    } catch (error) {
      if (!error) reject('Report Title Already Exists!');
      return reject(error);
    }
  });
};
export const createFacility = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const facility = await makeApiCall({
        url: '/Facility',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });

      return resolve(facility);
    } catch (error) {
      return reject(error);
    }
  });
};
export const updateFacility = (facilityId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const facility = await makeApiCall({
        url: '/Facility/' + facilityId,
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });

      return resolve(facility);
    } catch (error) {
      return reject(error);
    }
  });
};

export const forgetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const reset = await makeApiCall({
        url: '/Account/PasswordForget',
        method: 'post',
        data: data,
      });

      return resolve(reset);
    } catch (error) {
      return reject(error);
    }
  });
};

export const resetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const reset = await makeApiCall({
        url: '/Account/PasswordReset',
        method: 'post',
        data: data,
      });

      return resolve(reset);
    } catch (error) {
      return reject(error);
    }
  });
};

export const changePassword = (data) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const reset = await makeApiCall({
        url: '/Account/PasswordChange',
        method: 'post',
        data: data,
        headers: { Authorization: `bearer ${token}` },
      });
      return resolve(reset);
    } catch (error) {
      return reject('Reset failed. Unable to change password');
    }
  });
};

export const getPrivileges = () => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const privieleges = await makeApiCall({
        url: '/Privilege',
        method: 'get',
        headers: { Authorization: `bearer ${token}` },
      });
      return resolve(privieleges);
    } catch (error) {
      return reject('Something went wrong, Could not get privileged');
    }
  });
};

export const createRole = (data) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const role = await makeApiCall({
        url: '/Role',
        method: 'post',
        data: data,
        headers: { Authorization: `bearer ${token}` },
      });
      return resolve(role);
    } catch (error) {
      return reject('Something went wrong. Could not create new role');
    }
  });
};
// GET LIST OF GEOPOLITICAL ZONES
export const getZones = () => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const zones = await makeApiCall({
        url: '/Zone',
        method: 'get',
        headers: { Authorization: `bearer ${token}` },
      });
      return resolve(zones);
    } catch (error) {
      return reject(error);
    }
  });
};

// GET LIST OF LGA
export const getLga = (params) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    let url = `/Lga?Start=0&Stop=100000`;
    if (params) url = `/Lga${params}`;
    try {
      const lga = await makeApiCall({
        url: url,
        method: 'get',
        headers: { Authorization: `bearer ${token}` },
      });

      return resolve(lga);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status) return resolve(error);
      if (!error) return resolve(null);
      return reject('Error getting list of local governmant areas');
    }
  });
};

// GET LIST OF USERS
export const getUsers = (params) => {
  return new Promise(async (resolve, reject) => {
    let url = `/Account?Start=0&Stop=100000`;
    if (params) url = `/Account${params}`;
    try {
      const users = await makeApiCall({
        url: url,
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });

      return resolve(users);
    } catch (error) {
      return reject('Something went wrong. Could not get users.');
    }
  });
};

// SIGNUP USER
export const signup = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const signup = await makeApiCall({
        url: `/Account?InviteId=${id}`,
        method: 'post',
        data: data,
      });

      return resolve(signup);
    } catch (error) {
      return reject('Something went wrong. Could not sign up.');
    }
  });
};

// UPDATE PROFILE DETAILS
export const updateProfile = (data, userId) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const profile = await makeApiCall({
        url: `/Account/${userId}`,
        method: 'put',
        headers: { Authorization: `bearer ${token}` },
        data: data,
      });

      return resolve(profile);
    } catch (error) {
      return reject(error);
    }
  });
};

// GET LOOKUP DATA
export const getLookups = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const lookups = await makeApiCall({
        url: '/LookUp',
        method: 'get',
        header: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(lookups);
    } catch (error) {
      return reject('Something went wrong could not get lookups');
    }
  });
};

// GET FACILITIES IN A SECTOR
export const sectorFacility = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const facilities = await makeApiCall({
        url: `/Sector/${id}/Facilities`,
        method: 'get',
      });
      return resolve(facilities);
    } catch (error) {
      return reject('Something went wrong could not get facilities in this sector');
    }
  });
};

// GET FACILITIES IN LGA
export const lgaFacility = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const facilities = await makeApiCall({
        url: `/Lga/${id}/Facilities`,
        method: 'get',
      });
      return resolve(facilities);
    } catch (error) {
      return reject('Something went wrong could not get facilities in this Local Govt');
    }
  });
};

// CREATE NEW STATE
export const createState = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const state = await makeApiCall({
        url: '/State',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data,
      });
      return resolve(state);
    } catch (error) {
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (
        error &&
        error.result &&
        error.result.status &&
        error.result.status.desc === 'ALREADY EXISTS'
      )
        return reject('State already exists');
      if (!error) return reject('State already exists');
      return reject(error);
    }
  });
};

// CREATE NEW LOCAL GOVT
export const createLga = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const Lga = await makeApiCall({
        url: '/Lga',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(Lga);
    } catch (error) {
      return reject(error);
    }
  });
};

// CREATE NEW SECTOR
export const createSector = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const sector = await makeApiCall({
        url: '/Sector',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(sector);
    } catch (error) {
      return reject(error);
    }
  });
};

// UPDATE ZONE
export const updateZone = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const zone = await makeApiCall({
        url: '/Zone',
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(zone);
    } catch (error) {
      return reject('Something went wrong could update zone');
    }
  });
};

// UPDATE STATES
export const updateState = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const zone = await makeApiCall({
        url: '/State',
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(zone);
    } catch (error) {
      if (!error) reject('Cannot update to an existing state name');
      return reject(error);
    }
  });
};

// UPDATE LGA
export const updateLGA = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const LGA = await makeApiCall({
        url: '/Lga',
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(LGA);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (
        error &&
        error.result &&
        error.result.status &&
        error.result.status.desc === 'ALREADY EXISTS'
      )
        return resolve(error);
      if (!error) return resolve(null);
      return reject(error);
    }
  });
};

// UPDATE SECTOR
export const updateSector = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const sector = await makeApiCall({
        url: '/Sector',
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(sector);
    } catch (error) {
      return reject(error);
    }
  });
};

// GET DASHBOARD DATA
export const getDashboardData = (params) => {
  let url = '/Dashboard';
  if (params) url = `/Dashboard${params}`;
  return new Promise(async (resolve, reject) => {
    try {
      const dashboard = await makeApiCall({
        url,
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(dashboard);
    } catch (error) {
      return reject(error);
    }
  });
};

// GET DASHBOARD STAT
export const getDashboardStat = (params) => {
  let url = '/Dashboard';
  if (params) {
    if (params === "?StateId=''") url = `/Dashboard/Stats`;
    else url = `/Dashboard/Stats${params}`;
  }
  // if (param) url = `/Dashboard/Stats?StateId=101`;
  return new Promise(async (resolve, reject) => {
    try {
      const dashboardStat = await makeApiCall({
        url,
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(dashboardStat);
    } catch (error) {
      return reject(error);
    }
  });
};

export const createZone = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const zone = await makeApiCall({
        url: '/Zone',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(zone);
    } catch (error) {
      return reject('Something went wrong could not create new zone');
    }
  });
};

export const updateRole = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const role = await makeApiCall({
        url: '/Role',
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(role);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (
        error &&
        error.result &&
        error.result.status &&
        error.result.status.desc === 'ALREADY EXISTS'
      )
        return resolve(error);
      if (!error) return resolve(null);
      return reject('Error updating this role');
    }
  });
};

export const getPendingUsers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const pendingUsers = await makeApiCall({
        url: '/UserInvite',
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(pendingUsers);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (!error) return resolve(null);
      return reject('Error fetching list of users');
    }
  });
};

export const deleteState = (stateId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const state = await makeApiCall({
        url: `/State/${stateId}`,
        method: 'delete',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(state);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (!error) return resolve(null);
      return reject('Error deleting state');
    }
  });
};

export const updateUser = (UserId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await makeApiCall({
        url: `/Account/${UserId}/AddAreaPermissions`,
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(user);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (!error) return resolve(null);
      return reject(error);
    }
  });
};

export const deleteUserAssignedAreas = (UserId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await makeApiCall({
        url: `/Account/${UserId}/RemoveAreaPermissions`,
        method: 'delete',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(user);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (!error) return resolve(null);
      return reject(error);
    }
  });
};

export const addUserAssignedAreas = (UserId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await makeApiCall({
        url: `/Account/${UserId}/AddAreaPermissions`,
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(user);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (!error) return resolve(null);
      return reject(error);
    }
  });
};

export const deleteLga = (lgaId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const state = await makeApiCall({
        url: `/Lga/${lgaId}`,
        method: 'delete',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(state);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status && error.result.status.desc === 'EMPTY')
        return resolve(error);
      if (error && error.result && error.result.status && error.result.status.desc === 'NOT FOUND')
        return resolve(error);
      if (!error) return resolve(null);
      return reject(error);
    }
  });
};

export const searchLga = (stateId) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const lga = await makeApiCall({
        url: `/State/${stateId}/GetLga`,
        method: 'get',
        headers: { Authorization: `bearer ${token}` },
      });

      return resolve(lga);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status) return resolve(error);
      if (!error) return resolve(null);
      return reject(error);
    }
  });
};

export const getUser = (userId) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const user = await makeApiCall({
        url: `/Account?id=${userId}`,
        method: 'get',
        headers: { Authorization: `bearer ${token}` },
      });

      return resolve(user);
    } catch (error) {
      if (error && error.result && error.result.status) return resolve(error);
      if (!error) return resolve(null);
      return reject(error);
    }
  });
};
export const deleteZone = (zoneId) => {
  const token = getToken();
  return new Promise(async (resolve, reject) => {
    try {
      const zone = await makeApiCall({
        url: `/Zone/${zoneId}`,
        method: 'delete',
        headers: { Authorization: `bearer ${token}` },
      });

      return resolve(zone);
    } catch (error) {
      console.log('fetching error', error);
      if (error && error.result && error.result.status) return resolve(error);
      if (!error) return resolve(null);
      return reject(error);
    }
  });
};

// GET FACILITY BY SCHEDULE
export const getScheduleFacility = (quarter = 0, stateId = '', limit = 100) => {
  const currentYear = new Date().getFullYear();
  return new Promise(async (resolve, reject) => {
    //let url = `/Schedule?quarter=${quarter}&year=${currentYear}&limit=${limit}`;
    let url = `/Schedule?quarter=${quarter}&limit=${limit}`;
    let stateURL = `/Schedule?quarter=${quarter}&stateId=${stateId}&year=${currentYear}&limit=${limit}`;
    try {
      const scheduledFacility = await makeApiCall({
        url: stateId !== '' ? stateURL : url,
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });

      return resolve(scheduledFacility);
    } catch (error) {
      if (error && error.result && error.result.status) return resolve(error);
      return reject(error);
    }
  });
};

// GET SCHEDULE METRICS
export const getScheduleMetrics = (year) => {
  return new Promise(async (resolve, reject) => {
    let url = `/Schedule/Metrics?year=${year}`;
    try {
      const scheduledMetrics = await makeApiCall({
        url: url,
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });

      return resolve(scheduledMetrics);
    } catch (error) {
      return reject(error);
    }
  });
};

// SCHEDULE FACILITY
export const scheduleFacility = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const scheduledMetrics = await makeApiCall({
        url: '/Schedule',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });

      return resolve(scheduledMetrics);
    } catch (error) {
      console.log('schedule error', error);
      if (error && error.result && error.result.status) return resolve(error);
      return reject(error);
    }
  });
};

// GET ADMIN REPORT MENU
export const adminReportMenu = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const reportMenu = await makeApiCall({
        url: 'TelerikReports/ReportMenu',
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });

      return resolve(reportMenu);
    } catch (error) {
      return reject(error);
    }
  });
};

// CREATE NEW MEASUREMENT
export const createMeasurements = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const measurements = await makeApiCall({
        url: '/Measurement',
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(measurements);
    } catch (error) {
      reject(error);
    }
  });
};

// GET NOTIFICATION SETTINGS
export const getNotifications = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notifications = await makeApiCall({
        url: '/NotificationSetting',
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(notifications);
    } catch (error) {
      reject(error);
    }
  });
};

// ADD PARAMETER TO MEASUREMENT
export const addParameters = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const parameters = await makeApiCall({
        url: `/Measurement/${id}/AddParameters`,
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(parameters);
    } catch (error) {
      reject(error);
    }
  });
};

// UPDATE MEASUREMENT TYPE
export const updateMeasurement = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const measurement = await makeApiCall({
        url: `/Measurement/${id}`,
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(measurement);
    } catch (error) {
      reject(error);
    }
  });
};

// UPDATE NOTIFICATION SETTINGS
export const disableNotification = (settingName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notification = await makeApiCall({
        url: `/NotificationSetting/${settingName}/Disable`,
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(notification);
    } catch (error) {
      reject(error);
    }
  });
};

export const enableNotification = (settingName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notification = await makeApiCall({
        url: `/NotificationSetting/${settingName}/Enable`,
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(notification);
    } catch (error) {
      reject(error);
    }
  });
};

// REMOVER PARAMETERS FROM MEASUREMENT
export const removeMeasurementParameters = (id, parameterId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const measurementParameters = await makeApiCall({
        url: `/Measurement/${id}/RemoveParameter/${parameterId}`,
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(measurementParameters);
    } catch (error) {
      reject(error);
    }
  });
};

// GET USER PREFERENCES
export const fetchPreferenceSetting = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const notifications = await makeApiCall({
        url: `/PreferenceSetting/${userId}`,
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(notifications);
    } catch (error) {
      reject(error);
    }
  });
};

// ENABLE USER PREFERENCE
export const enableUserPreference = (userId, preferenceName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preference = await makeApiCall({
        url: `/PreferenceSetting/${userId}/Enable/${preferenceName}`,
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(preference);
    } catch (error) {
      reject(error);
    }
  });
};

// DISABLE USER PREFERENCE
export const disableUserPreference = (userId, preferenceName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preference = await makeApiCall({
        url: `/PreferenceSetting/${userId}/Disable/${preferenceName}`,
        method: 'post',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(preference);
    } catch (error) {
      reject(error);
    }
  });
};

// GET ROLES ACCORDING TO USER CATEGORY
export const getRolesByUserCategory = (userCategory) => {
  return new Promise(async (resolve, reject) => {
    try {
      const preference = await makeApiCall({
        url: `/Role/${userCategory}`,
        method: 'get',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(preference);
    } catch (error) {
      reject(error);
    }
  });
};

// DELETE SECTOR
export const deleteSector = (sectorId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const sector = await makeApiCall({
        url: `/Sector/${sectorId}`,
        method: 'delete',
        headers: { Authorization: `bearer ${getToken()}` },
      });
      return resolve(sector);
    } catch (error) {
      reject(error);
    }
  });
};

// EDIT MEASUREMENT PARAMETER
export const updateParameter = (parameterId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const parameter = await makeApiCall({
        url: `/Parameter/${parameterId}`,
        method: 'put',
        headers: { Authorization: `bearer ${getToken()}` },
        data: data,
      });
      return resolve(parameter);
    } catch (error) {
      reject(error);
    }
  });
};
